// https://github.com/deoostfreese/Parvus

import Parvus from "parvus";

document.addEventListener("DOMContentLoaded", function() {
  new Parvus({
    selector: "a[rel='lightbox']",
    transitionDuration: 200,
  });
});
